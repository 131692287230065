import cn from 'classnames';
import React, {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';
import classes from '../Textarea.module.scss';

interface Props {
  append?: ReactNode;
  innerRef?: any;
  autoFocus?: boolean;
  prepend?: ReactNode;
  wrapperClassName?: string;
  explanation?: boolean;
  comment?: boolean;
  overallFeedback?: boolean;
  isScrollable?: boolean;
}

export const TextareaBase = forwardRef<
  HTMLTextAreaElement,
  Props & HTMLAttributes<HTMLTextAreaElement>
>(
  ({
    className,
    wrapperClassName,
    // innerRef,
    append,
    prepend,
    explanation,
    comment,
    overallFeedback,
    isScrollable = false,
    ...textareaProps
  }) =>
    // ref
    {
      const textbox = useRef<HTMLTextAreaElement>(null);
      useLayoutEffect(() => {
        if (comment || explanation || (overallFeedback && !isScrollable)) {
          adjustHeight();
        } else if (overallFeedback && isScrollable) {
          handleOverallFeedbackInputHeight();
        }
      }, []);

      function adjustHeight() {
        textbox.current!.style.height = `${textbox.current!.scrollHeight}px`;
      }

      function handleOverallFeedbackInputHeight() {
        if (textbox.current!.scrollHeight < 95) adjustHeight();
        else textbox.current!.style.height = '95px';
      }

      function handleInput() {
        adjustHeight();
      }

      if (explanation) {
        return (
          <div className={cn(wrapperClassName, classes.textareaWrapper)}>
            <textarea
              ref={textbox}
              // ref={ref || innerRef}
              className={cn(className, classes.textarea)}
              {...textareaProps}
              style={{ overflow: 'hidden', height: '10px' }}
            />
            {prepend && <span className={classes.prepend}>{prepend}</span>}
            {append && <span className={classes.append}>{append}</span>}
          </div>
        );
      }

      if (comment) {
        return (
          <div className={cn(wrapperClassName, classes.textareaWrapper)}>
            <textarea
              ref={textbox}
              onInput={handleInput}
              // ref={ref || innerRef}
              className={cn(className, classes.textarea)}
              {...textareaProps}
              style={{ overflow: 'hidden', height: '10px' }}
            />
            {prepend && <span className={classes.prepend}>{prepend}</span>}
            {append && <span className={classes.append}>{append}</span>}
          </div>
        );
      }

      if (overallFeedback) {
        return (
          <div className={cn(wrapperClassName, classes.textareaWrapper)}>
            <textarea
              ref={textbox}
              onInput={handleOverallFeedbackInputHeight}
              // ref={ref || innerRef}
              className={cn(className, classes.textarea)}
              {...textareaProps}
              style={{
                overflowY: isScrollable ? 'scroll' : 'hidden',
                height: isScrollable ? '80px' : '10px',
              }}
            />
            {prepend && <span className={classes.prepend}>{prepend}</span>}
            {append && <span className={classes.append}>{append}</span>}
          </div>
        );
      }

      return (
        <div className={cn(wrapperClassName, classes.textareaWrapper)}>
          <textarea
            ref={textbox}
            // ref={ref || innerRef}
            className={cn(className, classes.textarea)}
            {...textareaProps}
          />
          {prepend && <span className={classes.prepend}>{prepend}</span>}
          {append && <span className={classes.append}>{append}</span>}
        </div>
      );
    }
);

export default TextareaBase;
