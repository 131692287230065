import { FC } from 'react';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const CreateQualificationFormButton: FC = () => {
  const { t } = useTranslation();
  return (
    <Button component={Link} to={`qualification-forms/create/new`}>
      {t('assessmentForms.newForm')}
    </Button>
  );
};
