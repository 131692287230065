import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  QualificationListRequest,
  QualificationListResponse,
  QualificationSaveRequest,
  QualificationUpdateRequest,
} from '../model/qualification';
import { qualificationService } from '../services/services';

const initialState = {
  qualifications: null as QualificationListResponse | null,
  status: 'idle',
  error: null as string | null,
};

export const fetchQualifications = createAsyncThunk(
  'qualification/fetch',
  ({
    limit,
    offset,
    instituteId,
    userId,
    isInstituteAdmin,
  }: QualificationListRequest) => {
    return qualificationService.getQualifications({
      limit,
      offset,
      instituteId,
      userId,
      isInstituteAdmin,
    });
  }
);

export const fetchQualificationById = createAsyncThunk(
  'qualificationById/fetch',
  (id: string) => {
    const response = qualificationService
      .getQualificationById(id)
      .then((response: any) => {
        if (response.error) {
          throw response;
        } else {
          return response;
        }
      });
    return response;
  }
);

export const postQualification = createAsyncThunk(
  'qualification/post',
  (data: QualificationSaveRequest) => {
    return qualificationService.postQualification(data);
  }
);
export const updateQualificationGeneralData = createAsyncThunk(
  'qualification/put',
  (params: { id: string; data: QualificationUpdateRequest }) => {
    return qualificationService.updateQualificationGeneralData(
      params.id,
      params.data
    );
  }
);

export const updateQualification = createAsyncThunk(
  'qualification/put',
  (params: { id: string; data: QualificationUpdateRequest }) => {
    return qualificationService.updateQualification(params.id, params.data);
  }
);

export const setQualificationStatusAsVerified = createAsyncThunk(
  'qualification/setStatus',
  (qualificationId: string) => {
    return qualificationService.setQualificationStatusAsVerified(
      qualificationId
    );
  }
);

export const deleteQualification = createAsyncThunk(
  'qualification/delete',
  (id: string) => {
    return qualificationService.deleteQualification(id);
  }
);

export const numberOfAssessedTopics = createAsyncThunk(
  'qualification/numberOfAssessedTopics',
  (qualificationId: string) => {
    try {
      return qualificationService.numberOfAssessedTopics(qualificationId);
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const qualificationSlice = createSlice({
  name: 'qualification',
  initialState,
  reducers: {},
  extraReducers: (boulder) => {
    boulder
      .addCase(fetchQualifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQualifications.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualifications = action.payload;
      })
      .addCase(fetchQualifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })
      .addCase(fetchQualificationById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQualificationById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qualifications = action.payload;
      })
      .addCase(fetchQualificationById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(postQualification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postQualification.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualifications = action.payload;
      })
      .addCase(postQualification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(updateQualification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQualification.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(updateQualification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(setQualificationStatusAsVerified.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setQualificationStatusAsVerified.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(setQualificationStatusAsVerified.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(deleteQualification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQualification.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualifications = action.payload;
      })
      .addCase(deleteQualification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })
      .addCase(numberOfAssessedTopics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(numberOfAssessedTopics.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(numberOfAssessedTopics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      });
  },
});

// export const {} = qualificationFormSlice.actions;

export default qualificationSlice.reducer;
