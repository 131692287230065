import { Col, Row, Text } from 'components';
import { withPageTitle } from 'containers/PageTitle';
import React, { ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  HISTORY_OPERATION,
  OPERATION_PLANNING,
  TEMPLATE,
  TEMPLATES_LIBRARY,
  ASSESSMENT_FORMS,
  CREATE_ASSESSMENT_FORM,
  PREVIEW_ASSESSMENT_FORM,
  PLAN_ASSESSMENT_FORM,
  EDIT_ASSESSMENT_FORM,
  EDIT_ASSESSMENT,
  EXECUTE_ASSESSMENT,
  PREVIEW_ASSESSMENT,
  VERIFY_ASSESSMENT,
  CREATE_QUALIFICATION_FORM_TOPICS,
  CREATE_QUALIFICATION_FORM_COMPETENCES,
  CREATE_QUALIFICATION_FORM,
  QUALIFICATION_GENERAL,
  QUALIFICATION_INFO,
  QUALIFICATION_CRITERIA,
  QUALIFICATION_FORMS,
} from 'scenes/routes.enum';
import classes from './Header.module.scss';
import { HeaderUser } from './HeaderUser';
import NewTemplateButton from './NewTemplateButton';
import {
  OperationHistoryTitle,
  OperationTitle,
  TemplateTitle,
} from './OperationTitleContainers';
import { CreateAssessmentButton } from '../AssessmentForms/components/CreateAssessmentButton';
import { useSelector } from 'react-redux';
import { selectInstitute, selectUser } from 'store/modules/auth';
import { AssessmentFormPageTitle } from '../AssessmentForms/components/assessmentForm/AssessmentFormPageTitle';
import { AssessmentPageTitle } from '../Assessments/AssessmentPageTitle';
import { QualificationPageTitle } from 'scenes/Qualification/QualificationPageTitle';
import { QualificationFormPageTitle } from 'scenes/QualificationForm/QualificationFormPageTitle';
import { CreateQualificationFormButton } from 'scenes/QualificationForm/CreateQualificationFormButton';

interface Props {
  pageTitle?: string;
}

const Header: ComponentType<Props> = ({ pageTitle = '' }) => {
  const institute = useSelector(selectInstitute);
  const user = useSelector(selectUser);
  const isInstituteAdmin =
    !!institute && !!user && institute.adminId === user.id;

  const planOpButtonExcludedRoutes = [
    OPERATION_PLANNING,
    TEMPLATE,
    CREATE_ASSESSMENT_FORM,
    PREVIEW_ASSESSMENT_FORM,
    !isInstituteAdmin ? ASSESSMENT_FORMS : '',
  ];

  return (
    <header className={classes.header}>
      <Row justifyContent="space-between" alignItems="center" nowrap>
        <Col className={classes.titleColumn}>
          <Switch>
            <Route path={OPERATION_PLANNING} component={OperationTitle} />
            <Route path={HISTORY_OPERATION} component={OperationHistoryTitle} />
            <Route path={TEMPLATE} component={TemplateTitle} />
            <Route
              path={[
                PREVIEW_ASSESSMENT_FORM,
                CREATE_ASSESSMENT_FORM,
                EDIT_ASSESSMENT_FORM,
                PLAN_ASSESSMENT_FORM,
              ]}
              component={AssessmentFormPageTitle}
            />
            <Route
              path={[
                PREVIEW_ASSESSMENT,
                EXECUTE_ASSESSMENT,
                VERIFY_ASSESSMENT,
                EDIT_ASSESSMENT,
              ]}
              component={AssessmentPageTitle}
            />
            <Route
              path={[
                QUALIFICATION_GENERAL,
                QUALIFICATION_INFO,
                QUALIFICATION_CRITERIA,
              ]}
              render={(props) => (
                <QualificationPageTitle {...props} user={user} />
              )}
            />
            <Route
              path={[
                CREATE_QUALIFICATION_FORM_TOPICS,
                CREATE_QUALIFICATION_FORM_COMPETENCES,
                CREATE_QUALIFICATION_FORM,
              ]}
              component={QualificationFormPageTitle}
            />
            <Text variant="heading-xl" className="m-0">
              {pageTitle}
            </Text>
          </Switch>
        </Col>
        <Col className={classes.menuColumn} auto>
          <Switch>
            <Route
              exact
              path={TEMPLATES_LIBRARY}
              component={NewTemplateButton}
            />
            <Route exact path={ASSESSMENT_FORMS}>
              {isInstituteAdmin && <CreateAssessmentButton />}
            </Route>
            {isInstituteAdmin && (
              <Route
                exact
                path={QUALIFICATION_FORMS}
                component={CreateQualificationFormButton}
              />
            )}

            {/* Hide Plan OP Button */}
            <Route
              path={planOpButtonExcludedRoutes}
              component={null as unknown as ComponentType}
            />
          </Switch>
          <HeaderUser />
        </Col>
      </Row>
    </header>
  );
};

export default withPageTitle(Header);
